import {DatePicker} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {useMemo, useRef, useState} from 'react';

import {DEFAULT_DATE_FORMAT} from '../../../constants/constants';
import {convertUtcToLocal, getAdjustedTimestampFromDayjs} from '../../../utils/utils';
import {DateFieldProps, InputType} from '../Fields';
import {useFieldOperations} from '../hooks/useFieldOperations';
import {handleDisabledDate, parseDateFormats} from '../utils';

const {RangePicker} = DatePicker;

export const DateRangePickerField = ({
    dateFormat,
    disabled,
    fieldValue,
    required,
    validations,
    forwardedRef,
    onChange,
    onCancel,
    className,
    dataTestId,
    timeZoneOffsetMins,
    fieldType,
}: DateFieldProps) => {
    const DATE_FORMAT = parseDateFormats(dateFormat) ?? DEFAULT_DATE_FORMAT;
    const hasDateChangedRef = useRef<boolean>(false);

    const {inputValue, handleOnChange, isError, handleOnBlur, handleKeyDown} =
        useFieldOperations<InputType>(
            fieldType,
            fieldValue,
            required,
            validations,
            onChange,
            onCancel
        );
    const [isOpen, setIsOpen] = useState(false);

    const values: [start: Dayjs | null, end: Dayjs | null] = useMemo(() => {
        if (
            Array.isArray(inputValue) &&
            inputValue.length === 2 &&
            inputValue.every((item) => typeof item === 'number')
        ) {
            const offsetFromUTCStart = dayjs(inputValue[0] * 1000).utcOffset();
            const offsetFromUTCEnd = dayjs(inputValue[1] * 1000).utcOffset();
            return [
                dayjs(
                    convertUtcToLocal(
                        (inputValue[0] - offsetFromUTCStart * 60) * 1000,
                        timeZoneOffsetMins
                    )
                ),
                dayjs(
                    convertUtcToLocal(
                        (inputValue[1] - offsetFromUTCEnd * 60) * 1000,
                        timeZoneOffsetMins
                    )
                ),
            ];
        } else {
            return [null, null];
        }
    }, [inputValue]);

    const onDateChange = (dates: [Dayjs | null, Dayjs | null] | null, isCalenderChange = false) => {
        !isCalenderChange && (hasDateChangedRef.current = true);
        if (dates && dates[0] && dates[1]) {
            const [startDateLocal, endDateLocal] = dates;
            const startDate = getAdjustedTimestampFromDayjs(startDateLocal, timeZoneOffsetMins);
            const endDate = getAdjustedTimestampFromDayjs(endDateLocal, timeZoneOffsetMins);
            handleOnChange([startDate, endDate]);
            !isCalenderChange && onChange([startDate, endDate], !isError);
        } else if (!dates || (dates[0] == null && dates[1] == null)) {
            handleOnChange(fieldValue);
            !isCalenderChange && onChange(fieldValue, !isError);
        }
    };
    const handleOnOpenChange = (open: boolean) => {
        if (!open && isOpen) {
            !hasDateChangedRef.current && handleOnBlur();
        }
        setIsOpen(open);
    };
    const handleOnKeyDown = (event: React.KeyboardEvent<any>) => {
        if (isOpen && event.key === 'Escape') {
            event.stopPropagation();
            onCancel(true);
        }
        !isOpen && handleKeyDown(event);
    };

    return (
        <RangePicker
            className={className}
            value={values}
            format={DATE_FORMAT}
            autoFocus
            ref={forwardedRef}
            disabled={disabled}
            data-testid={dataTestId}
            onBlur={isOpen ? undefined : handleOnBlur}
            onChange={(dates) => onDateChange(dates)}
            onCalendarChange={(dates) => onDateChange(dates, true)}
            disabledDate={(current) => handleDisabledDate(current, timeZoneOffsetMins, validations)}
            onOpenChange={handleOnOpenChange}
            onKeyDown={handleOnKeyDown}
        />
    );
};
