import {DatePicker} from 'antd';
import dayjs from 'dayjs';
import {useMemo, useState} from 'react';

import {StyledPanel} from '../../../common/date-range-picker/DateRangePicker.styled';
import {DEFAULT_DATE_FORMAT} from '../../../constants/constants';
import {DateFormatEnum} from '../../../constants/fields';
import {convertLocalToUTC, convertUtcToLocal} from '../../../utils/utils';
import {DateFieldProps, InputType} from '../Fields';
import {useFieldOperations} from '../hooks/useFieldOperations';
import {handleDisabledDate} from '../utils';
import {DateRangePickerField} from './DateRange';

export const DatePickerField = (props: DateFieldProps) => {
    const {
        dateFormat,
        disabled,
        fieldValue,
        required,
        validations,
        forwardedRef,
        onChange,
        onCancel,
        className,
        dataTestId,
        timeZoneOffsetMins,
        fieldType,
    } = props;
    const DATE_FORMAT = dateFormat ?? DEFAULT_DATE_FORMAT;
    const isDateTimeField = DATE_FORMAT !== DEFAULT_DATE_FORMAT;
    const isDateRangeField = DATE_FORMAT === DateFormatEnum.DateRange;

    if (isDateRangeField) {
        return <DateRangePickerField {...props} />;
    }
    const {inputValue, handleOnChange, isError, handleOnBlur, handleKeyDown} =
        useFieldOperations<InputType>(
            fieldType,
            fieldValue,
            required,
            validations,
            onChange,
            onCancel
        );
    const dateValue = useMemo(() => {
        if (!(typeof inputValue === 'number') && !inputValue) {
            return null;
        } else {
            const utcOffset = dayjs(Number(inputValue) * 1000).utcOffset();
            const localDate = convertUtcToLocal(
                (Number(inputValue) - utcOffset * 60) * 1000,
                timeZoneOffsetMins
            );
            return dayjs(localDate);
        }
    }, [inputValue]);
    const [isOpen, setIsOpen] = useState(false);
    const handleChange = (date: any) => {
        if (!date) {
            handleOnChange(date);
            return;
        }
        const nowDate = convertUtcToLocal(undefined, timeZoneOffsetMins);
        const dateObj = new Date(date);

        const timestamp = isDateTimeField
            ? dateObj.getTime()
            : Date.UTC(
                  date.year(),
                  date.month(),
                  date.date(),
                  nowDate.getHours(),
                  nowDate.getMinutes(),
                  nowDate.getSeconds()
              );

        const localDate = convertLocalToUTC(timestamp, timeZoneOffsetMins);
        const newDate = isDateTimeField
            ? dayjs(localDate).utc(true).unix()
            : dayjs(localDate).unix();

        handleOnChange(newDate);
        onChange(newDate, !isError);
    };

    const handleOnKeyDown = (event: React.KeyboardEvent<any>) => {
        if (isOpen && event.key === 'Escape') {
            event.stopPropagation();
            onCancel(true);
        }
        !isOpen && handleKeyDown(event);
    };

    const handleOnOpenChange = (open: boolean) => {
        if (!open && isOpen) {
            onCancel(true);
        }
        setIsOpen(true);
    };

    return (
        <DatePicker
            className={className}
            value={dateValue}
            format={DATE_FORMAT}
            showTime={isDateTimeField}
            onChange={handleChange}
            required={required}
            style={{flexGrow: 1}}
            autoFocus
            ref={forwardedRef}
            disabledDate={(current) => handleDisabledDate(current, timeZoneOffsetMins, validations)}
            disabled={disabled}
            onOpenChange={handleOnOpenChange}
            data-testid={dataTestId}
            panelRender={(originalPanel) => {
                return <StyledPanel>{originalPanel}</StyledPanel>;
            }}
            onKeyDown={handleOnKeyDown}
            onBlur={isOpen ? undefined : handleOnBlur}
        />
    );
};
