import {Typography} from 'antd';
import moment from 'moment';

import {convertUtcToLocal} from '../../../utils/utils';
import {DateFieldProps} from '../Fields';

const {Text} = Typography;
import dayjs from 'dayjs';

import {DEFAULT_DATE_FORMAT} from '../../../constants/constants';
import {parseDateFormats} from '../utils';

const TEST_DATE = '01/13/2000';

export const DateRangeText = ({dateFormat, fieldValue, timeZoneOffsetMins}: DateFieldProps) => {
    if (!(fieldValue && Array.isArray(fieldValue) && fieldValue.length === 2)) return null;
    const format = parseDateFormats(dateFormat) ?? DEFAULT_DATE_FORMAT;
    const offsetFromUTCStart = dayjs(fieldValue[0] * 1000).utcOffset();
    const offsetFromUTCEnd = dayjs(fieldValue[1] * 1000).utcOffset();

    const startDate = convertUtcToLocal(
        (fieldValue[0] - offsetFromUTCStart * 60) * 1000,
        timeZoneOffsetMins
    );
    const endDate = convertUtcToLocal(
        (fieldValue[1] - offsetFromUTCEnd * 60) * 1000,
        timeZoneOffsetMins
    );

    const fetchFormat = () => {
        const formattedDateString = moment(new Date(TEST_DATE)).format(format);
        const parsedDate = moment(formattedDateString, format, true);
        return parsedDate.isValid() ? format : DEFAULT_DATE_FORMAT;
    };
    const formattedStartDate = moment(startDate).format(fetchFormat());
    const formattedEndDate = moment(endDate).format(fetchFormat());

    return <Text>{`${formattedStartDate} - ${formattedEndDate}`}</Text>;
};
