import {getFeatureFlagValue} from '@citrite/feature-flags';
import {Typography} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';

import {DEFAULT_DATE_FORMAT} from '../../../constants/constants';
import {DateFormatEnum} from '../../../constants/fields';
import {FeatureFlag} from '../../../featureFlags';
import {convertUtcToLocal} from '../../../utils/utils';
import {DateFieldProps} from '../Fields';
import {DateRangeText} from './DateRange';

const {Text} = Typography;
const TEST_DATE = '01/13/2000';

export const DateText = (props: DateFieldProps) => {
    const {dateFormat, fieldValue, timeZoneOffsetMins} = props;
    if (!(typeof fieldValue === 'number') && !fieldValue) return null;
    const isDateRangeField = dateFormat === DateFormatEnum.DateRange;
    if (isDateRangeField) {
        return <DateRangeText {...props} />;
    }
    const offsetFromUTCLocal = dayjs(Number(fieldValue) * 1000).utcOffset();

    const dateValue = convertUtcToLocal(
        (Number(fieldValue) - offsetFromUTCLocal * 60) * 1000,
        timeZoneOffsetMins
    );
    const showDateTime = getFeatureFlagValue(FeatureFlag.enableDateTime);

    const fetchFormat = () => {
        const initialFormat = showDateTime
            ? dateFormat ?? DEFAULT_DATE_FORMAT
            : DEFAULT_DATE_FORMAT;
        const formattedDateString = moment(new Date(TEST_DATE)).format(initialFormat);
        const parsedDate = moment(formattedDateString, initialFormat, true);
        return parsedDate.isValid() ? initialFormat : DEFAULT_DATE_FORMAT;
    };

    const formattedDate = moment(dateValue).format(fetchFormat());

    return <Text>{formattedDate}</Text>;
};
