import {colorPalette, sizes} from '@sharefiledev/antd-config';
import {projectsExtensionSlots} from '@sharefiledev/projects-pilet';
import type {PiletApi} from '@sharefiledev/sharefile-appshell';
import {Flex} from 'antd';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import React from 'react';

import {addBlocks} from './Blocks';
import {AsyncLazyBlockDevelopment} from './Development';
import {DataTable} from './layouts/table';
import AsyncLazyApp from './LazyApp';
import {
    AsyncLazyClientViewDrawer,
    AsyncLazyCreateTableModal,
    AsyncLazyDeleteTableModal,
    AsyncLazyEditTableModal,
    AsyncLazyExportTableModal,
    AsyncLazyRecordViewDrawer,
} from './overlays';
import {dataTablesExtensionSlots} from './sdk';
import {piletOverlays} from './sdk/extensionTypes';
import {t} from './utils';
import {Icons} from './utils/icons/icon';
import {setLogger} from './utils/logger';
import {TranslatedString} from './utils/TranslatedString';

export const setup = (app: PiletApi) => {
    dayjs.extend(weekday);
    dayjs.extend(localeData);
    dayjs.extend(utc);

    setLogger(app.sf.getLogger());
    registerApp(app);

    app.registerExtension(projectsExtensionSlots.tabs, ({params}) => (
        <AsyncLazyApp piletApi={app} params={params} />
    ));

    // data tables tab in Projects.
    app.registerExtension(dataTablesExtensionSlots.projectTab, ({params}) => (
        <AsyncLazyApp piletApi={app} params={params} />
    ));

    const drawerPropsRenderer = {
        drawerProps: {
            title: t('dynamic-components:form.title.update'),
            width: '700px',
            footer: null,
        },
    };
    // service-provider drawer
    app.registerDrawer(
        piletOverlays.viewRequestRecordViewDrawer,
        ({options, cancelInteraction, completeInteraction}) => (
            <AsyncLazyRecordViewDrawer
                piletApi={app}
                completeInteraction={completeInteraction}
                cancelInteraction={cancelInteraction}
                containerRID={options?.containerRID || ''}
                isProjectFrozen={options?.readonly ?? false}
            />
        ),
        {
            ...drawerPropsRenderer,
        }
    );

    // client-view drawer
    app.registerDrawer(
        piletOverlays.clinetViewRequestRecordViewDrawer,
        ({options, cancelInteraction, completeInteraction}) => (
            <AsyncLazyClientViewDrawer
                piletApi={app}
                completeInteraction={completeInteraction}
                cancelInteraction={cancelInteraction}
                containerRID={options?.params?.containerRID || ''}
            />
        ),
        {
            ...drawerPropsRenderer,
        }
    );

    // create-table modal
    app.registerModal(
        piletOverlays.createTableModal,
        (props) => <AsyncLazyCreateTableModal {...props} />,
        {
            modalProps: {
                title: (
                    <ModalTitle>
                        <TranslatedString localizationKey="dynamic-components:addTab.createTable" />
                    </ModalTitle>
                ),
                centered: true,
                width: 500,
                footer: null,
                styles: {
                    body: {
                        height: '230px',
                    },
                },
            },
        }
    );

    // edit-table modal
    app.registerModal(
        piletOverlays.editTableModal,
        (props) => <AsyncLazyEditTableModal {...props} />,
        {
            modalProps: {
                title: (
                    <ModalTitle>
                        <TranslatedString localizationKey="dynamic-components:editTab.modalTitle" />
                    </ModalTitle>
                ),
                centered: true,
                width: 500,
                footer: null,
                styles: {
                    body: {
                        height: '120px',
                    },
                },
            },
        }
    );

    app.registerModal(
        piletOverlays.exportTableModal,
        (props) => <AsyncLazyExportTableModal {...props} />,
        {
            modalProps: {
                title: (
                    <TranslatedString localizationKey="dynamic-components:exportTab.modalTitle" />
                ),
                centered: true,
                width: 500,
                footer: null,
            },
        }
    );

    // delete-table modal
    app.registerModal(
        piletOverlays.deleteTableModal,
        (props) => <AsyncLazyDeleteTableModal {...props} />,
        {
            modalProps: {
                title: (
                    <Flex gap={8}>
                        <Icons.WarningCircleFilled color={colorPalette.red6} />
                        <TranslatedString localizationKey="dynamic-components:deleteModal.title" />
                    </Flex>
                ),
                centered: true,
                width: 400,
                footer: null,
                closeIcon: false,
            },
        }
    );

    if (process.env.NODE_ENV === 'development') {
        app.sf.registerLeftNavComponent({
            href: '/dynamic-tables-dev',
            title: () => 'Test Local',
            icon: () => null,
        });

        app.registerPage('/dynamic-tables-dev', () => <AsyncLazyBlockDevelopment piletApi={app} />);
    }
};

const registerApp = (app: PiletApi) => {
    addBlocks(app);

    // extension slot for data-table library
    app.registerExtension(dataTablesExtensionSlots.dataTable, ({params}) => (
        <DataTable {...params} />
    ));
};

const ModalTitle: React.FC<{children: React.ReactNode}> = ({children}) => (
    <span style={{position: 'relative', bottom: sizes.base}}>{children}</span>
);
